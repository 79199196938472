// require('./bootstrap');

// slick functionality
// const $slick = $('[data-slick]');
function app() {

    $('input, select').attr('autocomplete', 'off');

    $('[data-slick]').slick(
        {
            initialSlide: 0,
            touchThreshold: 50,
            speed: 50,
            adaptiveHeight: false,
            dots: true,
            arrows: true,
            nextArrow: '<svg class="slick-right slick-next" xmlns="http://www.w3.org/2000/svg" width="12.735" height="44.469" viewBox="0 0 12.735 44.469"> <path id="Path_84195" data-name="Path 84195" d="M330.276,404.257l11.558,21.558-11.558,21.558" transform="translate(-329.599 -403.58)" fill="none" stroke="#1d1d1b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>',
            prevArrow: '<svg class="slick-left slick-prev" xmlns="http://www.w3.org/2000/svg" width="12.811" height="44.47" viewBox="0 0 12.811 44.47"><path id="Path_84195" data-name="Path 84195" d="M341.909,404.257l-11.634,21.558,11.634,21.558" transform="translate(-329.776 -403.579)" fill="none" stroke="#1d1d1b" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/></svg>'
        }
    )

// input placeholders
    const $input = $('.custom-form-group').find('input');

    function initialLabel() {
        $input.each(function () {
            const $group = $(this).closest('.custom-form-group');
            const $label = $group.find('label');
            const $input = $group.find('input');

            if ($input.val() && $input.val().length) {
                $label.css({top: '6px'})
            } else {
                $label.css({top: '20px'})
            }

            $input.on('focus', function () {
                const $label = $(this).closest('.custom-form-group').find('label');

                $label.css({top: '6px'})
            })

            $input.on('change', function () {
                const $this = $(this);
                const $label = $this.closest('.custom-form-group').find('label');

                if ($input.val() && $this.val().length) {
                    $label.css({top: '6px'})
                } else {
                    $label.css({top: '20px'})
                }
            })

            $input.on('blur', function () {
                const $this = $(this);
                const $label = $this.closest('.custom-form-group').find('label');

                if ($input.val() && $this.val().length) {
                    $label.css({top: '6px'})
                } else {
                    $label.css({top: '20px'})
                }
            })

        });
    }

    // setTimeout(() => {
        initialLabel()

    // }, 0)

    // Livewire.hook('message.received', (message, component) => {
    //     setTimeout(() => {
    //         initialLabel()
    //     })
    // })


    const $mobileMenu = $('#mobile-menu');
// toggle mobile menu
    $('#burger').on('click', function () {
        $mobileMenu.show()
    })

    $('#close-mobile').on('click', function () {
        $mobileMenu.hide()
    })


//  onchange="this.dispatchEvent(new InputEvent('input'))"
// datepicker initialisation
    $('[data-datepicker]').datepicker({
        format: 'dd.mm.yyyy',
        autoHide: true,
        // startDate: new Date(),
        days: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'нд'],
        daysMin: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'нд'],
        daysShort: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'нд'],
        months: ['Січень', 'лютий', 'березень', 'квітень', 'травень', 'червень', 'липень', 'серпень', 'вересень', 'жовтень', 'листопад', 'грудень']
    }).on('pick.datepicker', function (e) {
        const val = $(this).datepicker('getDate', true)
        $(this).val(val);
    });


    function threeDaysDuration() {
        const days = 3;
        const $startDate = $('#check_in');
        const $endDate = $('#check_out');


        // console.log($startDate);

        $startDate.datepicker('setStartDate', new Date())

        function duration() {
            const val = $startDate.datepicker('getDate', true)
            // console.log(val);
            const startDateObject = new Date(val.split('.').reverse().join('-'));
            const date = new Date(startDateObject);
            date.setDate(date.getDate() + days);

            $startDate.datepicker('setStartDate', new Date())

            $endDate.datepicker('setStartDate', new Date(startDateObject));
            $endDate.datepicker('setEndDate', date);
            $endDate.datepicker('setDate', date);
        }

        if($startDate.length && $endDate.length){
            duration()
            $startDate.datepicker().on('pick.datepicker', duration)
        }
    }

    threeDaysDuration()

}



document.addEventListener("DOMContentLoaded", () => {
    app()
    Livewire.hook('message.processed', (message, component) => {    app()})
});
